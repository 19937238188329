@import "../../styles/constants";

@keyframes rotate-newspaper {
  0% {
    transform: scale(0.5) rotate(360deg)
  }
  100% {
    transform: scale(1) rotate(-360deg)
  }
}

@keyframes spin {
  0% {
    opacity: 0.2;
    transform: scale(0.2) rotate(0deg);
  }

  15% {
    opacity: 1;
    transform: scale(1) rotate(1090deg);
  }

  90% {
    opacity: 1;
    ransform: scale(1) rotate(1090deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) rotate(1090deg);
  }
}

.dialog-font {
  font-family: 'Alegreya Sans', sans-serif;
  color: black;
  font-size: 15px;
}

.stories-page {
  min-width: 34rem;
  @media screen and (min-width: $mobileWidth) {
    min-width: 1200px;
  }

  .story-page-container {
    @media screen and (min-width: $mobileWidth) {
      width: 1200px;
    }

    max-width: 95%;

    .faction-shadow {
      filter: drop-shadow(0.56rem 0.56rem 0 $title-shadow);

      div {
        @media screen and (min-width: 800px) {
          clip-path: polygon(0% 0%, 100% 0, 97% 100%, 2% 97%);
        }

      }
    }

    .story-container {
      background-color: white;
      padding: 3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (min-width: $mobileWidth) {
        padding: 3rem 5rem;
      }

      .animate-news {
        animation-duration: 6s;
        animation-name: spin;
        nimation-timing-function: linear;
        transform: scale(1);
        //animation: rotate-newspaper;
        //animation-duration: 1s;
      }

      .vote-button {

        &:active {
          transform: scale(0.8);
          transition: transform 0.5s;
        }

        &.disabled {
          pointer-events: none;
          border: 3px solid #6b6b6b;
          background-color: #a7a7a7;
          box-shadow: none;
          color: white;
        }

        cursor: pointer;
        background-color: transparent;
        color: #ED9AC8;
        font-size: 20px;
        font-weight: 700;
        box-shadow: 0 0 13px #F6B0E1;
        border: 2px #ED9AC8 solid;
        padding: 0.4rem 2.5rem;
        border-radius: 18px;
      }

      .vote-box {
        cursor: pointer;
        padding: 2rem;
        border: 1px solid #707070;
        box-shadow: 8px 8px #00000029;

        &.pressed {
          border: 8px solid #F6B0E1;
        }

      }
    }

  }
}
