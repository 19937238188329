@import "../../../styles/constants";

.sewer-box {
  //filter: drop-shadow(0.56rem 0.56rem 0 #B0F6CA);
  display: flex;
  flex-direction: column;
  align-items: center;

  .sewer-container {
    //border: solid 0.375rem #5BFF56;
    background-color: white;
    //clip-path: polygon(-30% -30%, 100% -46%, 99% 100%, 0% 99%);

    .text-container {
      @media screen and (min-width: $mobileWidth) {
        font-size: 1rem;
        max-width: 55%;
        bottom: 20%;
        transform: translate(40%, 13%);
      }
      transform: translate(20%, 15%);
      padding: 2rem;
      font-size: 0.9rem;
      max-width: 75%;
      bottom: 10%;
    }
  }
}