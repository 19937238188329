.arrow-shadow-custom {
    filter: drop-shadow(0.2rem 0.2rem 0 #C4DBFF);

    &.right {
        filter: drop-shadow(-0rem 0rem 0 #C4DBFF);
    }

    .arrow-dropdown {
        margin-left: 0.2rem;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-left: 12px solid #F6B0E1;
        border-bottom: 6px solid transparent;
        transition: transform 0.5s;
        transform: rotate(90deg);;

        &.animate {
            transform: rotate(270deg);
        }
    }
}