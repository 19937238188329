@import "../styles/constants";

.button-container {

  filter: drop-shadow(0.3rem 0.3rem 0 #2776BB);
  cursor: pointer;

  .polygon-wrapper {

    &:active{
      box-shadow: 0 5px #666;
      transform: translateY(4px);
      animation-duration: 500ms;

    }

    @media screen and (max-width: $mobileWidth) {
      padding: 0.8rem 1.5rem;
    }

    padding: 0.4rem 0.8rem;
    background: #4DA7F6 0 0 no-repeat padding-box;
    clip-path: polygon(0% 10%, 100% 0, 97% 100%, 2% 90%);

    .label-wrapper {
      font-size: 1.3rem;
      padding-top: 0.2rem;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 2rem;

      .image-wrapper {
        margin-left: 1rem;
        margin-right: 1rem;
        width: 2rem;
      }

    }

  }

}