.vote-polygon-container {
  background-image: url("../../../assets/containerImages/vote/palms_grey.png");
  clip-path: polygon(0% 0%, 100% 0%, 98% 98%, 0% 100%);
  padding-bottom: 2rem;
  background-color: black;

  .vote-button{

    &:active{
      transform: scale(0.8);
      transition: transform 0.4s;
    }

    cursor: pointer;
    background-color: black;
    color: #ED9AC8;
    font-size: 20px;
    font-weight: 700;
    filter: drop-shadow(0.1rem 0.1rem 20px #F6B0E1);;
    border: 2px #ED9AC8 solid;
    padding: 0.6rem 2.5rem;
    border-radius: 18px;
  }

}