@import "../../../styles/constants";

.roadmap-container {
  background-color: #1C1C1C;
  clip-path: polygon(-30% -30%, 100% -46%, 96% 100%, 0% 99.6%);
  @media screen and (min-width: $mobileWidth) {
    padding-bottom: 12rem;
    clip-path: polygon(-30% -30%, 100% -46%, 96% 100%, 0% 99%);
  }
  padding-bottom: 7rem;
  position: relative;

  .roadmap-progress-green {
    margin-left: 1.04rem;
    width: 0.75rem;
    background-color: #49FF55;
    height: 85%;
    margin-top: 2rem;

    &:after{
      border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
      margin-left: -0.62rem;
      position: absolute;
      content: "";
      bottom: -0.2rem;
      display: inline-block;
      width: 1.9rem;
      height: 1.9rem;
      border-right: 0.5em solid #F6B0E1;
      border-top: 0.47em solid #F6B0E1;
      transform: rotate(135deg);
      margin-right: 0.5em;
    }
    &:before{
      border-radius: 0.3rem;
      content: "";
    }
  }

  .checkmark_stem {
    position: absolute;
    width:3px;
    height:9px;
    background-color:#fff;
    left:11px;
    top:6px;
  }

  .checkmark_kick {
    position: absolute;
    width:3px;
    height:3px;
    background-color:#fff;
    left:8px;
    top:12px;
  }

  .roadmap-progress-pink {
    margin-left: 1.04rem;
    width: 0.75rem;
    background-color: #F6B0E1;
    height: 34.2%;
    margin-top: 2rem;
  }

  .status-container {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    margin-right: 2rem;
    margin-top: 1.5rem;

    &.checked {
      background-color: #49FF55;
    }

    &.pending {
      background-color: #F6B0E1;
    }
  }

  .title-box-shadow {
    filter: none;

    .title-container {
      background-color: #FFFFFF;
    }
  }

  .container {
    .phase-wrapper {
      max-width: 90%;
      @media screen and (min-width: $mobileWidth) {
        max-width: 70%;
      }
    }

    .phase-container {
      &.checked {
        background-color: #C4FFDA;
        border: 6px solid #49FF55;
        clip-path: none;
      }

      @media screen and (min-width: $mobileWidth) {
        max-width: 90%;
      }
      padding: 1rem 2rem;
      margin-bottom: 1rem;
      background-color: #F8FFBC;

      .underline {

        &:after {
          content: "";
          display: block;
          margin-top: 0.3rem;
          border-bottom: 5px solid #F6B0E1;
          transform: rotate(-0.8deg);
        }

        &.phase1 {
          &:after {
            width: 16rem;
          }
        }

        &.phase2 {
          &:after {
            width: 21rem;
          }
        }

        &.phase3 {
          &:after {
            width: 28rem;
            max-width: 85%;
          }
        }

        &.phase4 {
          &:after {
            width: 27rem;
            max-width: 85%;
          }
        }

        &.phase7 {
          &:after {
            width: 10rem;
          }
        }

        &.phase-default {
          &:after {
            width: 15rem;

            max-width: 80%;
          }
        }
      }
    }

    .title-box-shadow {
      transform: rotate(0deg);
    }
  }

  .city-bg {
    @media screen and (min-width: $mobileWidth) {
      transform: translateY(-99.8rem);
    }
    transform: translateY(-40.7rem);
  }


}
