@import "./styles/constants";

@font-face {
  font-family: 'parkinson';
  src: url('assets/font/stormfaze.ttf') format('truetype');
}

@font-face {
  font-family: 'parkinson';
  src: url('assets/font/Parkinson - Amboy-Black.otf') format('truetype');
}

.App {
  min-width: 34rem;

  .navbar {
    box-shadow: none;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }

  .font-retro {
    font-family: "parkinson", serif;
  }

}

.transactions-toast-list_toastContainer {
  background-color: whitesmoke;
}

.transactions-toast-list_wrapper {
  clip-path: polygon(0% 1%, 100% 0, 97% 100%, 2% 96%);
  border-radius: 0 !important;
}

.progress {
  border-radius: 0 !important;
}


.progress-bar {
  z-index: 2000;
}

.progress {
  border-radius: 0.5rem !important;
}

.content-height {
  z-index: 2000;
}

.toast-messages .toast {
  display: block;
  flex-basis: 4rem;
  width: 100%;
  max-width: 28.875rem;
  transition: transform 0.3s ease-in-out;
  transform: translateX(120%);
  background-color: #2b2d2e;
  border-radius: 0.75rem;
  border-color: #49494d;
}


.toast-messages .toast {
  display: block;
  flex-basis: 4rem;
  width: 100%;
  max-width: 28.875rem;
  transition: transform 0.3s ease-in-out;
  transform: translateX(120%);
  background-color: #2b2d2e;
  border-radius: 0.75rem;
  border-color: #49494d;
}

.toast-messages .toast.toast-visible .progress .progress-bar {
  background-color: rgba(155, 165, 180, 0.2);
}

.tx-details_item {
  & svg {
    color: #6c757d !important;
  }
}

.wallect-connect-login_card {
  background-color: transparent !important;
}

.btn-primary {
  border-color: transparent !important;

  &:hover {
    border-color: transparent !important;
  }
}

.dapp-core-component__dappModalStyles__dappModal {

  z-index: 1055 !important;

  .dapp-core-component__dappModalStyles__dappModalContent {
    border-radius: 0 !important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;

    .dapp-core-component__dappModalStyles__dappModalHeader {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      font-family: "parkinson", serif;

      .component__walletConnectLoginContainerStyles__xPortal-modal-close-button {
        padding: 0.6rem 0.8rem !important;
      }

      .dapp-core-component__dappModalStyles__dappModalCloseButton {
        position: relative;
        background-color: #4DA7F6!important;
        padding: 0.6rem 0.8rem !important;

        svg {
          display: block!important;
          color: white!important;
        }
      }
    }

    .dapp-core-component__main__card {
      border-bottom: none !important;
    }

    margin-top: -5rem;

    @media screen and (min-width: $mobileWidth) {
      min-width: 42rem !important;
      min-height: 28rem !important;
      margin-top: 0;
    }

    .dapp-modal-dialog-content-body {
      padding: 0 !important;
    }

    clip-path: polygon(0% 2%, 100% 0, 96% 100%, 6% 96%);
    min-width: 30rem;
    min-height: 30rem !important;

    .dapp-core-component__main__h4 {
      display: none;
    }

    .wallect-connect-login_leadText {
      height: 2rem;
    }
  }

  .card-title {
    font-family: parkinson, serif !important;
  }
}


#closeButton {
  &:active {
    box-shadow: 0 5px #666;
    transform: translateY(4px);
    background-color: #ffbee7 !important;
    animation-duration: 500ms;
  }


  background-color: #ffbee7;
  color: white !important;
  clip-path: polygon(0% 0%, 100% 0, 97% 100%, 2% 97%);
  border-radius: 0;
}

.scale-animate {

  &:active {
    transform: scale(0.7);
    transition: transform 0.1s;
  }
}

.page-state {
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }

  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #ffbee7;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .bg-blue .lds-ellipsis div {
    background: #ffbee7;
  }

  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

.dapp-core-component__main__btn, #accessWalletBtn {
  padding: 0.8rem 2.5rem;
  background: #4DA7F6 0 0 no-repeat padding-box!important;
  white-space: nowrap;
  clip-path: polygon(0% 2%, 100% 0, 97% 100%, 2% 90%);
  text-align: center;
  border-color: transparent !important;
  border-radius: 0 !important;

  &:active {
    box-shadow: 0 5px #666;
    transform: translateY(4px);
    animation-duration: 500ms;

  }

  svg {
    display: none;
  }
}

app-core-component__dappModalStyles__dappModalCloseButton {

}


