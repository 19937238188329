.token-symbol{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2c2d2f;
  border-radius: 50%;
  flex-shrink: 0;
  border: 1px solid #49494d;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  width: 2rem;
  height: 2rem;
}