@import "../../../styles/constants";

.team-container {
  background-color: white;
  clip-path: polygon(2% 0%, 100% -10%, 98% 100%, 0% 100%);
  padding-bottom: 2rem;

  .title-box-shadow {

    margin-bottom: 1rem;

    .title-container {
      background-color: #C3FFBC;
    }
  }

  .grow-animation {
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }

  .kosy-shadow {
    filter: drop-shadow(0.56rem 0.56rem 0 #4BE8C1);

    .kosy-container {
      clip-path: polygon(2% 0%, 100% 2%, 96% 100%, 0% 100%);
    }
  }

  .tyrion-shadow {
    filter: drop-shadow(0.56rem 0.56rem 0 #61B6EC);

    .tyrion-container {
      clip-path: polygon(3% 0%, 100% 1%, 100% 99%, 0% 100%);
    }
  }

  .mla-shadow {
    filter: drop-shadow(0.56rem 0.56rem 0 #FBDF89);

    .mla-container {
      clip-path: polygon(0% 0%, 100% 0%, 98% 100%, 1% 100%);;
    }
  }

  .dalv-shadow {
    filter: drop-shadow(0.56rem 0.56rem 0 #3085EA);

    .dalv-container {
      clip-path: polygon(0% 0%, 98% 1%, 100% 98%, 0% 100%);
    }
  }
}