@import "../../../styles/common";

//@keyframes slideUp {
//  0% {
//    transform: translateY(300px);
//    opacity: 0;
//  }
//  100% {
//    transform: translateY(0px);
//    opacity: 1;
//  }
//}

.scale-0 {
  animation: fade-in-keyframes 1s ease-in-out;
}

.scale-1 {
  animation: fade-in-keyframes 1s ease-in-out;
  animation-delay: 1s;
}

.scale-2 {
  animation: fade-in-keyframes 1s ease-in-out;
  animation-delay: 2s;
}

.scale-3 {
  animation: fade-in-keyframes 1s ease-in-out;
  animation-delay: 3s;
}

.scale-4 {
  animation: fade-in-keyframes 1s ease-in-out;
  animation-delay: 4s;
}


@keyframes fade-in-keyframes {
  0% {transform: scale(1)}
  50% {transform: scale(1.2)}
  100% {transform: scale(1)}
}

//.SlideDownAnimation {
//  opacity: 1;
//  animation: slideDown 2s ease-in-out forwards;
//}
//
//.animate-faction {
//  transform: scale(1.2);
//}

.factions-container {
  background-color: white;
  clip-path: polygon(-30% -30%, 100% 0, 100% 100%, 0% 98%);
  padding-bottom: 2rem;
  @media screen and (min-width: $mobileWidth) {
    clip-path: polygon(-30% -30%, 100% 0, 100% 100%, 0% 95%);
  }

  .title-box-shadow {
    .title-container {
      background-color: #E2BCFF;
    }
  }

  .top-right-bg {
    position: absolute;
    max-width: 12rem;
    transform: translateX(63rem);
  }

  .bottom-right {
    position: absolute;
    max-width: 7rem;
    transform: translate(64rem, 31rem);
  }

  .bottom-left {
    position: absolute;
    max-width: 10rem;
    transform: translateY(29rem);
  }

  .faction {
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover {
      @media screen and (min-width: $mobileWidth) {
        transform: scale(1.2);
      }
    }

    &:after {
      content: "";
      display: block;
      width: 70%;
      z-index: 2;
      transform: translateY(-12px);
      margin: 0 auto 0;
      border-bottom: 5px solid #F6B0E1;
    }
  }
}

.empty-banner-faction {
  clip-path: polygon(0% 21%, 100% 69%, 97% 100%, 2% 97%);
  background: #FFFFFF 0 0 no-repeat padding-box;
}