@import "styles/constants.scss";
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,400;1,100;1,300;1,500&display=swap');

#root {
  min-width: 34rem;
}

html {
  min-width: 34rem;
}

body {
  background-size: cover;
  min-width: 34rem;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  background: transparent linear-gradient(180deg, #FDD2EF 0%, #FFF3FB 100%) no-repeat fixed 0 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
