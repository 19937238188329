@import "../../../styles/constants";

$bottomPadding: -16rem;
.welcome-container {
  @media screen and (min-width: $mobileWidth) {
    padding-bottom: 0;
  }
  background-color: #89C3FF;
  clip-path: polygon(-290% -30%, 100% -46%, 99% 100%, 0% 99%);
  @media screen and (min-width: $mobileWidth) {
    max-height: 545px;
  }
  min-height: 545px;

  .text-container {
    @media screen and (min-width: $mobileWidth) {
      font-size: 1rem;
      bottom: 30%;
      background-color: transparent;
      padding: 2rem 8rem;
    }
    padding: 1.5rem 1rem;
    background-color: #E6A1C7;
    max-width: 65%;
    z-index: 1;
    margin-left: 20%;
    margin-top: 16%;
  }
}

.welcome-sign {
  position: relative;
  justify-content: center;
  @media screen and (min-width: $mobileWidth) {
    transform: translateY($bottomPadding);
  }
  transform: translateY(-5rem);


  .welcome-text {
    position: absolute;
    @media screen and (min-width: $mobileWidth) {
      bottom: 3rem;
      padding: 0 10rem;
      background-color: transparent;
    }

    bottom: -16rem;
    margin: 0 5rem 0 6rem;
    --webkit-margin-end: 2rem;
    padding: 2rem;
    background-color: #E8A6C2;

    &.safari-margin {
      @media screen and (max-width: $mobileWidth) {
        margin: 0 5rem 0 2rem;
      }
    }
  }
}