@import "./constants";

.image-full-size {
  width: 100%;
  height: 100%;
}

.box-shadow {
  filter: drop-shadow(0.56rem 0.56rem 0 $shadow-pink);
}

.title-box-shadow {
  filter: drop-shadow(0.56rem 0.56rem 0 $title-shadow);
  transform: rotate(-2deg);
}