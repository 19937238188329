.og-page {
  .banner-container {
    clip-path: none !important;
    background-image: none !important;
    background-color: black;
  }

  .list-container {
    background-color: white;
    clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
  }

  .og-card {
    border: 2px solid black;
    padding: 0.6rem;
    margin: 1rem 1rem;
    cursor: pointer;

    .image-wrapper {
      max-width: 11rem;
      margin-bottom: 0.7rem;

      .rating {
        position: absolute;
        top: -8px;

        .star {
          max-width: 15%;
        }
      }

      img {
        border: 1px solid black;
        border-radius: 1rem;
      }
    }

    .strip {
      height: 0.5rem;
      background-color: #ff8181;
      margin-bottom: 0.5rem;
    }

    .info-box-shadow {
      filter: drop-shadow(0.36rem 0.36rem 0 black);
      top: -4px;

      .info-container {
        background-color: #7eb3eb !important;
        padding-right: 3rem;
        padding-left: 0.6rem;
        clip-path: polygon(0% -50%, 100% 0, 97% 94%, 2% 100%);
      }
    }
  }

  .up-button {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 40px;
    text-align: center;

    .polygon-wrapper {
      padding: 0.9rem 0;
      font-size: 2rem !important;
    }
  }
}
