@import "../../styles/constants";

@font-face {
  font-family: 'parkinson';
  src: url('../../assets/font/stormfaze.ttf') format('truetype');
}

@font-face {
  font-family: 'airstrikecond';
  src: url('../../assets/font/airstrikecond.ttf') format('truetype');
}

.faction-banner-container {
  background-color: white;
  box-shadow: 0.56rem 0.56rem $shadow-pink;

  .logo-container {
    position: absolute;
    transform: translate(-18rem, -2rem);

  }

  .story-container {
    background-color: white;
    padding-bottom: 3rem;

    .title {
      font-size: 2.5rem;
      font-family: airstrikecond, serif;
      color: #F6B0E1;
    }

    .faction-shadow {
      filter: drop-shadow(0.56rem 0.56rem 0 $title-shadow);

      div{
        clip-path: polygon(0% 0%, 100% 0, 97% 100%, 2% 97%);
      }
    }

    ul {
      list-style-type: none;

      li:before {
        content: '\2013';
        position: absolute;
        margin-left: -20px;
      }
    }

    .faction-logo {
      display: flex;
      justify-content: center;
      margin-top: -2.5rem;

      .faction-title-shadow{
        filter: drop-shadow(0.56rem 0.56rem 0 $title-shadow);
      }
    }

    .navigation-link {
      cursor: pointer;
      color: #4DA7F6;
      text-decoration: underline;
      font-weight: 700;
    }
  }
}