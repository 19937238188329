@import "../../styles/constants";

.customize-character-container {
  clip-path: polygon(0% 0%, 100% 0%, 98% 98%, 0% 100%);
  padding-bottom: 2rem;
  background-color: white;
}

.carousel-element {
  background-color: transparent;
  width: 500px;
  height: 500px;
}

.arrow {
  margin-top: 0.7rem;
  width: 0;
  height: 0;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  transition: transform 0.5s;

  &.left {
    border-left: 44px solid #F6B0E1;
  }

  &.right {
    border-right: 44px solid #F6B0E1;
  }
}

.arrow-shadow {
  filter: drop-shadow(0.56rem 0.56rem 0 #C4DBFF);

  &.right {
    filter: drop-shadow(-0.30rem 0.50rem 0 #C4DBFF);
  }
}

.carousel-control-prev {
  @media screen and (min-width: $mobileWidth) {
    left: -100px !important;
  }
}

.carousel-control-next {
  @media screen and (min-width: $mobileWidth) {
    right: -100px !important;
  }
}