@import "../../../styles/constants";

@keyframes rotate {
  50% {
    transform: rotate(90deg);
  }
}

.faq-container {
  background-color: white;
  clip-path: polygon(0% 0%, 100% -34%, 99% 100%, 0% 100%);
  margin-top: 2rem;

  .title-box-shadow {

    margin-bottom: 1rem;

    .title-container {
      background-color: #C4DBFF;
    }
  }

  .questions {
    padding-left: 2rem;
    @media screen and (min-width: $mobileWidth) {
      padding-left: 5.3rem;
    }

    .question-container {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding-bottom: 1rem;

      .arrow-shadow {
        filter: drop-shadow(0.26rem 0.26rem 0 #C4DBFF);
      }

      .arrow {
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-left: 22px solid #F6B0E1;
        border-bottom: 12px solid transparent;
        transition: transform 0.5s;

        &.animate {
          transform: rotate(90deg);
        }
      }
    }

    .answer {
      transition: all 4s ease-in-out;
    }
  }

}