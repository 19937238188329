@import "../../styles/constants";

.modal-dialog-centered {
  max-width: 370px !important;
  margin: auto!important;

  @media screen and (min-width: $mobileWidth) {
    max-width: 615px !important;
  }
  clip-path: polygon(0% 1%, 100% 0, 97% 100%, 2% 96%);

  .modal-content {
    border-radius: 0;

    .no-wrap_loginText {
      white-space: nowrap;
    }

    .button-shadow {
      filter: drop-shadow(0.3rem 0.3rem 0 #2776BB);
      @media screen and (max-width: $mobileWidth) {
        padding-bottom: 0.5rem;
      }

      .btn-primary {

        &:active{
          box-shadow: 0 5px #666;
          transform: translateY(4px);
          background-color: #4DA7F6!important;
          animation-duration: 500ms;
        }

        clip-path: polygon(0% 0%, 100% 0, 97% 100%, 2% 97%);
        background: #4DA7F6 0 0 no-repeat padding-box;
        border-radius: 0;
      }
    }

    .button-shadow-secondary {
      filter: drop-shadow(0.3rem 0.3rem 0 $shadow-pink);

      .btn-secondary {

        &:active{
          box-shadow: 0 5px #666;
          transform: translateY(4px);
          background-color: #ffbee7!important;
          animation-duration: 500ms;
        }

        color: white;
        background-color: #ffbee7;
        clip-path: polygon(0% 0%, 100% 0, 97% 100%, 2% 97%);
        border-radius: 0;
      }
    }
  }

  .btn-secondary {
    margin-right: 1rem;
  }
}

.wallect-connect-login_card{
  box-shadow: none!important;
}