@import "../../styles/constants";

.story-banner-container{
  min-width: 22rem;
  padding-bottom: 2rem;
  @media screen and (min-width: $mobileWidth) {
    max-width: 100%;
    padding-bottom: 0;
  }
  background-color: black;
  background-size: contain;

  .logo-container {
    background-image: url("../../assets/backgrounds/miami_bg.webp");
  }
}