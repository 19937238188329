@import "../../styles/constants";

.landing-page {
  min-width: 34rem;
  @media screen and (min-width: $mobileWidth) {
    min-width: 1700px;
  }

  .login-button{
    max-width: 6rem;
    .label{
      font-size: 1rem;
    }
  }

  .address{
    a {
      color: #4DA7F6;
    }
  }

  .page-container {
    @media screen and (min-width: $mobileWidth) {
      width: 1200px;
    }
    max-width: 95%;

    .navbar-toggler {
      border: none;
      outline: none;
      box-shadow: none;
      z-index: 2;

      &:focus, &:active {
        outline: none;
        box-shadow: none;
      }
    }

    .title-container {
      background-color: #F8FFBC;
      padding: 1rem 6rem;
      margin-top: -2rem;
      margin-bottom: 2rem;
      font-weight: 700;
    }

    .banner-container {
      min-width: 22rem;
      min-width: 100%;
      @media screen and (min-width: $mobileWidth) {
        max-width: 100%;
        clip-path: polygon(0% 0%, 100% -8%, 94% 93%, -192% 100%);
      }
      background-image: url("../../assets/backgrounds/logo_wallpaper.webp");
      background-size: contain;
      clip-path: polygon(0% -50%, 100% 0, 97% 94%, 2% 100%);

      .logo-container {
        background-image: url("../../assets/backgrounds/miami_bg.webp");
      }
    }

    .discord-banner-container {
      filter: drop-shadow(0.56rem 0.56rem 0 #00000029);
      max-width: 85%;

      .content {
        @media screen and (min-width: $mobileWidth) {
          max-width: 88.5%;
          font-size: 0.9rem;
          padding: 1.5rem 1rem;
          text-align: justify;
        }

        margin-bottom: 1rem;
        transform: rotate(4deg);
        clip-path: polygon(0% 0%, 100% 0, 99% 100%, 0% 100%);
        min-width: 50%;
        padding: 3rem 1.5rem;
        background: #FFFFFF 0 0 no-repeat padding-box;

        .amount{
          font-family: parkinson, serif;
          font-size: 20px;
        }

        .arrow-shadow {
          filter: drop-shadow(0.26rem 0.26rem 0 #C4DBFF);

          &.right {
            filter: drop-shadow(-0.15rem 0.25rem 0 #C4DBFF);
          }
        }

        .arrow {
          margin-top: 0.7rem;
          width: 0;
          height: 0;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          transition: transform 0.5s;

          &.left {
            border-left: 22px solid #F6B0E1;
          }

          &.right {
            border-right: 22px solid #F6B0E1;
          }
        }

      }
    }

    .empty-banner {
      clip-path: polygon(0% 64%, 100% 0, 98% 31%, 2% 97%);
      background: #FFFFFF 0 0 no-repeat padding-box;
    }
  }

  .footer {
    @media screen and (min-width: $mobileWidth) {
      padding-bottom: 1.5rem;
    }
    position: relative;
    background-color: #BDE2FF;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;


    .footer-landscape {
      @media screen and (min-width: $mobileWidth) {
        position: absolute;
        right: 0;
        bottom: 0;
      }

    }
  }

}
