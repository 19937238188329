@import "../../../styles/constants";

.specs-banner-container {
  padding-bottom: 6rem;
  background-image: url("../../../assets/containerImages/collectionSpecs/bg_big.webp");
  @media screen and (max-width: $mobileWidth) {
    background-size: cover;
  }

  .specs-banner-title-container {
    background-color: #FFFF4A;
    padding: 1rem 6rem;
    margin-top: -2rem;
    margin-bottom: 2rem;
    font-weight: 700;
  }

  .box-shadow-pink {
    filter: drop-shadow(0.56rem 0.56rem 0 #FFD2F2);
  }

  .box-shadow-blue {
    filter: drop-shadow(0.56rem 0.56rem 0 #D2F1FF);
  }

  .box-shadow-yellow {
    filter: drop-shadow(0.56rem 0.56rem 0 #F6F1B0);
  }

  .arrow {
    width: 0;
    height: 0;
    margin-right: 1rem;
    border-top: 8px solid transparent;
    border-left: 14px solid #F6B0E1;
    border-bottom: 8px solid transparent;
  }

  .spec-wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    margin: 0 auto;
  }

  .specs-container {
    padding-bottom: 2rem;
    background-color: #FFF5FC;
    margin: 0 3rem 5rem;

    @media screen and (min-width: $mobileWidth) {
      height: 100%;
      margin: 0 1.7rem;
    }

    &.pink {
      background-color: #FFF5FC;
    }

    &.blue {
      background-color: #F3FBFF;
    }

    &.yellow {
      background-color: #FEFFF1;
    }

    .specs-title-container {
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 1rem;
      margin-top: -2rem;
      margin-bottom: 2rem;
      transform: translateY(-1rem);

      &.pink {
        background-color: #FFD2F2;
      }

      &.blue {
        background-color: #D2F1FF;
      }

      &.yellow {
        background-color: #FCFFD2;
      }
    }
  }

  .rarity-container {
    background-color: white;
    clip-path: polygon(0% -15%, 100% 0, 98% 100%, 2% 98%);
    padding-bottom: 2rem;
    @media screen and (min-width: $mobileWidth) {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    .rarity-characters-container {
      @media screen and (min-width: $mobileWidth) {
        max-width: 60%;
      }
      max-width: 90%;
      background-color: black;
      clip-path: polygon(2% 0%, 100% 0, 100% 100%, 0% 100%);
    }

    .top-left {
      position: absolute;
      max-width: 5rem;
      transform: translateX(-2rem);
    }

    .bottom-left {
      position: absolute;
      max-width: 6rem;
      transform: translate(-2rem, 15rem);

    }
  }
}