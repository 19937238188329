@import "../../styles/constants";

.old-app {
  @media screen and (max-width: $mobileWidth) {
    height: 100%;
  }

  .main-page-container {
    height: 100%;
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: stretch;
  }

  .logo-container {
    display: block;
    margin-top: 6rem;
    max-width: 61%;
    position: relative;

    .audio-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      left: -19%;
      top: -19%;

      @media screen and (min-width: $mobileWidth) {
        left: 2%;
        top: -1.5%;
      }

      @keyframes slideInFromLeft {
        0% {
          transform: translateX(-180%);
          opacity: 0;
        }
        100% {
          transform: translateX(0);
          opacity: 100;
        }
      }

      .dialog-wrapper {
        animation: 1s ease-out 0s 1 slideInFromLeft;
        display: flex;

        .dialog-box-shadow {

          filter: drop-shadow(0.3rem 0.25rem 0 #B3E6FB);

          .dialog-box {
            width: 8rem;
            height: 3.5rem;
            background-color: white;
            clip-path: polygon(0% 0%, 100% 4%, 101% 85%, 6% 99%);
            text-align: center;
            display: flex;
            align-items: center;

            span {
              margin: auto;
              font-size: 0.8rem;
            }
          }
        }

        .box-arrow-shadow {
          width: 1.125rem;
          height: 1.125rem;
          background-color: transparent;
          filter: drop-shadow(0.3rem 0.25rem 0 #B3E6FB);
          margin-right: 0.5rem;
          margin-top: 1rem;

          .box-arrow {
            transition: 1s;
            left: 0;
            z-index: 100;
            border-style: solid;
            border-width: 0.3rem 0 0.8rem 1.2rem;
            clip-path: polygon(0% 0%, 100% 0%, 0% 100%, 2% 97%);
            border-color: white white white white;
          }
        }
      }

      .audio-control {
        cursor: pointer;
        background: transparent;
        box-sizing: border-box;
        width: 0;
        height: 4.625rem;

        border-color: transparent transparent transparent #F6B0E1;
        transition: 100ms all ease;

        border-style: double;
        border-width: 0 0 0 3.75rem;

        &.paused {
          // play state
          border-style: solid;
          border-width: 2.312rem 0 2.312rem 3.75rem;
        }

        &:hover {
          border-color: transparent transparent transparent #f6bde3;
        }
      }
    }
  }

  .box-shadow {
    min-width: 50%;
    max-width: 93%;
    @media screen and (min-width: $mobileWidth) {
      max-width: 55%;
    }

    .old-welcome-message-container {
      clip-path: polygon(0% 0%, 100% 0, 97% 100%, 2% 97%);
      min-width: 50%;

      padding: 3rem 5rem;
      background: #FFFFFF 0 0 no-repeat padding-box;

      @media screen and (min-width: $mobileWidth) {
        text-align: center;
      }

      .first-line {
        @media screen and (min-width: $mobileWidth) {
          line-height: 1.4rem;
        }
      }

      span {
        padding-bottom: 1rem;
        display: block;
      }

      .social-media {
        padding-top: 1rem;
        display: flex;
        justify-content: center;

        @media screen and (min-width: $mobileWidth) {
          max-width: 50%;
          justify-content: space-around;
          margin: auto;
        }

        .image-wrapper {
          width: 4rem;
          padding: 0.2rem 0.7rem;

          &:active {
            fill: $shadow-pink !important;
          }

          @media screen and (min-width: $mobileWidth) {
            cursor: pointer;
            //width: 2rem;
            padding: 0.5rem 1rem;

            .logo {
              transition: 100ms all ease;

              &:hover {
                fill: $shadow-pink !important;
              }
            }
          }
        }
      }
    }
  }

  .footer-image {
    position: relative;
    margin-top: auto;
    align-self: flex-end;
    width: 100%;
    overflow: hidden;

    img {
      object-fit: cover;
    }

    .credits {
      position: absolute;
      display: flex;
      flex-direction: column;

      font-size: 0.7rem;
      top: 83%;
      left: 35%;
      color: white;
      align-items: center;

      a {
        color: white;
        text-decoration: white;

        @media screen and (min-width: $mobileWidth) {

          font-size: 0.8rem;
        }
      }

      @media screen and (min-width: $mobileWidth) {
        top: 88%;
        left: 43%;
      }
    }
  }


}